<template>
  <v-responsive :aspect-ratio="1"
                width="100px"
                min-width="100px"
                max-width="125px"
                class="justify-center align-center cursor-pointer text-center flex-grow-1 ma-1 image"
  >
    <div class="image-actions">
      <v-btn icon
             tile
             color="white"
             class="black"
             x-small
             @click="openImage()"
      >
        <v-icon small>$search</v-icon>
      </v-btn>
    </div>
    <v-img
      :src="image.url"
      :lazy-src="image.url"
      aspect-ratio="1"
      max-height="100%"
      max-width="100%"
    />
  </v-responsive>
</template>

<script>

import EventBus from '@/application/eventBus.js';

export default {
  name: 'TicketImage',
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openImage(){
      EventBus.$emit('expandImage', { imgUrl: this.image.url });
    }
  },
};
</script>

<style>

.image-actions{
  position: absolute;
  z-index: 1;
  top: -3px;
  right: 0px;
}

.image{
  border: 1px solid gray;
}

</style>
