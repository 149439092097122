<template>
  <detail-layout
    ref="layout"
    :ticketId="ticketId"
    :ticketDetails="ticketDetails"
    :reload="indexRequest">
  </detail-layout>
</template>

<script>
import EventBus from '@/application/eventBus.js';
import DetailLayout from '@/modules/tickets/components/DetailLayout.vue';
import { i18n } from '@/plugins';
import { TicketDetail } from '@/application/models/Ticket.js';
import { show } from '@/modules/tickets/api/ticket.js';
import { mapGetters } from 'vuex';

export default {
  name: 'TicketsDetail',
  components: { DetailLayout },
  data() {
    return {
      ticketDetails: new TicketDetail(),
      isPending: false,
      isPendingSubmit: false,
      isPendingRemove: [],
      timeoutIndexRequest: null, //@TODO is this used?
    };
  },
  computed: {
    ...mapGetters('settings', { clientId: 'getCurrentClientId' }),
    ticketId() {
      return this.$route.params.ticketId;
    },
  },
  async beforeRouteEnter(to, from, next) {
    try {
      next(async (vm) => {
        const { data: { data } } = await show(vm.clientId, to.params.ticketId);
        vm.setTicketDetails(data);
      });
    } catch (e) {
      EventBus.$emit('snackbar', {
        color: 'error',
        text: i18n.t('errors.defaultErrorWrapper', [i18n.t('errors.fetchOrderData')]),
      });
      next();
    }
  },
  methods: {
    async reload() {
      const { data: { data } } = await show(this.clientId, this.$route.params.ticketId);
      this.setTicketDetails(data);
    },
    setTicketDetails(data) {
      this.ticketDetails = new TicketDetail().mapResponse(data);
      this.ticketDetails.isLoaded = true;
    },
    async indexRequest(...args) { //@TODO is this used?
      this.isPending = true;
      try {
        const { data: { data } } = await show(this.clientId, this.ticketId);
        this.setTicketDetails(data);
      } catch (e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.fetchOrderData')]),
        });
      }
      this.isPending = false;
    },
  },
};
</script>
