<template>
  <div>
    <v-timeline-item hide-dot v-if="isDateVisible" class="timeline-date">
      <v-chip v-if="isDateVisible"
              class="grey white--text font-weight-medium">{{ comment.date }}</v-chip>
    </v-timeline-item>
    <v-timeline-item hide-dot :right="isSender" :left="!isSender">
      <v-card color="background" class="elevation-0 rounded-xl" flat>
        <v-card-title class="pt-2 pb-0">
          <v-row no-gutters>
            <div class="text--disabled body-2 font-italic">{{ comment.userName }}</div>
            <VSpacer/>
            <div class="text--primary body-1 font-weight-medium">{{ comment.time }}</div>
          </v-row>
        </v-card-title>
        <v-card-text class="text--primary body-2">
          {{ comment.comment }}
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </div>
</template>

<script>
import OrderComment from '@/application/models/Comment.js';

export default {
  name: 'Comment',
  props: {
    comment: {
      type: OrderComment,
      required: true,
    },
    isDateVisible: {
      type: Boolean,
    },
    isSender: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.timeline-date .v-timeline-item__divider {
  display: none;
}
.v-timeline.comments-timeline .v-timeline-item.timeline-date div.v-timeline-item__body{
  max-width:100%!important;
  text-align:center
}
</style>
