<template>
  <page-layout>
    <template #header>
      <k-title>
        <router-link :to="{name: 'tickets.index'}" class="secondary--text">{{ $t('ticket.tickets.menuTitle') }}
        </router-link>
        / {{ $t('ticket.tickets.details.menuTitle', [ticketId]) }}
      </k-title>
    </template>
    <template #flow-actions>
      <v-btn color="secondary" depressed tile outlined @click="handleOpenUpdateDialog" class="mr-3">
        {{ $tc('actions.edit') }}
      </v-btn>
      <UpdateTicketDetails v-model="updateDialog"
                           :request="updateRequest"
                           :title="$t('ticket.tickets.editTitle')"
                           :values="updateFormValues"/>
    </template>
    <template #default>
      <v-fade-transition>
        <v-row class="mt-6">
          <v-col cols="12" lg="7">
            <k-display-group language-prefix="ticket.tickets.display">
              <v-row>
                <v-col cols="12">
                  <v-sheet class="pa-4">
                    <KSubtitle class="pa-0">{{ $t('ticket.tickets.details.ticketDetails') }}</KSubtitle>
                    <VSkeletonLoader v-if="!ticketDetails.isLoaded" type="card-heading"/>
                    <div v-else>
                      <v-row class="mt-2" no-gutters>
                        <v-col cols="4">
                          <k-text-display class="mb-3" field="status">
                            {{ status }}
                          </k-text-display>
                          <k-text-display class="mb-3 text-pre-line" field="location">
                            {{ address }}
                          </k-text-display>
                          <k-text-display class="mb-3 text-pre-line" field="contactInfo">
                            {{ user }}
                          </k-text-display>
                        </v-col>
                        <v-col cols="4">
                          <k-text-display class="mb-3" field="creationDate">
                            {{ ticketDetails.creationDate }}
                          </k-text-display>
                          <k-text-display class="mb-3 text-pre-line" field="assignedTo">
                            {{ ticketDetails.assignedUserInfo.name != null ? assignedUser : $t('ticket.tickets.display.unassigned')
                            }}
                          </k-text-display>
                          <k-text-display class="mb-3" field="assignDate">
                            {{ assignedDate }}
                          </k-text-display>
                        </v-col>
                        <v-col cols="4">
                          <k-text-display class="mb-3" field="startDate">
                            {{ inProgressDate }}
                          </k-text-display>
                          <k-text-display class="mb-3" field="elapsedTime">
                            {{ elapsedTime }}
                          </k-text-display>
                          <k-text-display class="mb-3" field="resolvedTime">
                            {{ resolvedDate }}
                          </k-text-display>
                        </v-col>
                      </v-row>
                    </div>
                  </v-sheet>
                </v-col>
                <v-col cols="12">
                  <v-sheet class="pa-4 fill-height">
                    <KSubtitle class="pa-0">{{ $t('ticket.tickets.details.subjectDetails') }}</KSubtitle>
                    <VSkeletonLoader v-if="!ticketDetails.isLoaded" type="card-heading"/>
                    <div v-else>
                      <v-row class="mt-2" no-gutters>
                        <v-col cols="6">
                          <k-text-display class="mb-3" field="subject">
                            {{ ticketDetails.subject }}
                          </k-text-display>
                          <k-text-display class="mb-3" field="description">
                            {{ ticketDetails.description }}
                          </k-text-display>
                          <span class="d-inline-flex k-label text--secondary font-italic"
                                v-if="ticketDetails.relatedOrder.id != null"
                                :style="{width: '100%'}">
                            {{ $t('ticket.tickets.display.relatedOrder')}}
                          </span>
                          <v-btn class="mt-1"
                                 v-if="ticketDetails.relatedOrder.id != null"
                                 color="secondary"
                                 depressed
                                 outlined
                                 :to="{name: 'orders.show', params: {orderId: ticketDetails.relatedOrder.id}}"
                                 tile>
                            {{ $t('ticket.tickets.display.order')}} #{{ ticketDetails.relatedOrder.id }}
                          </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <TicketImageGroup
                            :ticketImages="ticketDetails.images"
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </v-sheet>
                </v-col>
              </v-row>
            </k-display-group>
          </v-col>
          <v-col cols="12" lg="5">
            <div class="group-chat-wrapper">
              <Comments :title="$t('ticket.commentsTitle')"
                        :comments-text-area-label-text="$t('ticket.commentsTextAreaLabelText')"
                        :add-button-text="$t('ticket.commentsButtonText')"
                        :index-request="getComments"
                        :create-request="addComment"/>
            </div>
          </v-col>
        </v-row>

      </v-fade-transition>
    </template>
  </page-layout>
</template>

<script lang="js">
import { TicketDetail } from '@/application/models/Ticket.js';
import UpdateTicketDetails from '@/modules/tickets/components/UpdateTicketDetails.vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import ticketStatus from '@/application/enums/ticketStatus.js';
import TicketImageGroup from '@/modules/tickets/components/TicketImageGroup.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import KTitle from '@/components/layout/KTitle.vue';
import Comments from '@/components/Comments/Comments.vue';
import { addComment, getComments, updateTicket } from '@/modules/tickets/api/ticket.js';
import { mapGetters } from 'vuex';

export default {
  name: 'DetailLayout',
  components: {
    Comments,
    KTitle,
    TicketImageGroup,
    PageLayout,
    KDisplayGroup,
    KSubtitle,
    KTextDisplay,
    UpdateTicketDetails,
  },
  data() {
    return {
      updateDialog: false,
      updateFormValues: new TicketDetail(),
    };
  },
  props: {
    ticketId: {
      type: [Number, String],
      required: true,
    },
    ticketDetails: {
      type: TicketDetail,
      required: true,
    },
    reload: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapGetters('settings', { clientId: 'getCurrentClientId' }),
    address() {
      return this.ticketDetails.address.addressLine1 + '\n' + this.ticketDetails.address.city + '\n' + this.ticketDetails.address.zipCode + '\n' + this.$t(`countries.${this.ticketDetails.address.countryId}`);
    },
    user() {
      const phone = this.ticketDetails.contactInfo.phone != null ? this.ticketDetails.contactInfo.phone : '-';
      return this.ticketDetails.contactInfo.name + '\n' + phone + '\n' + this.ticketDetails.contactInfo.email;
    },
    assignedUser() {
      const phone = this.ticketDetails.contactInfo.phone != null ? this.ticketDetails.contactInfo.phone : '-';
      return this.ticketDetails.assignedUserInfo.name + '\n' + phone + '\n' + this.ticketDetails.assignedUserInfo.email;
    },
    assignedDate(){
      return this.ticketDetails.assignedDate != '' ? this.ticketDetails.assignedDate : this.$t('ticket.tickets.display.unavailable')
    },
    inProgressDate(){
      return this.ticketDetails.inProgressDate != '' ? this.ticketDetails.inProgressDate : this.$t('ticket.tickets.display.unavailable')
    },
    elapsedTime(){
      const elapsed = new Date(this.ticketDetails.elapsedTime * 1000).toISOString().substr(11, 8);
      return this.ticketDetails.elapsedTime != '' ? elapsed : this.$t('ticket.tickets.display.unavailable')
    },
    resolvedDate(){
      return this.ticketDetails.resolvedDate != '' ? this.ticketDetails.resolvedDate : this.$t('ticket.tickets.display.unavailable')
    },
    status() {
      return this.$t(`ticket.ticketStatus.${Object.keys(ticketStatus)
        .find(key => ticketStatus[key] === this.ticketDetails.ticketStatusId)}`);
    },
  },
  methods: {
    handleOpenUpdateDialog() {
      this.updateFormValues.assignedUserInfo.id = this.ticketDetails.assignedUserInfo.id;
      this.updateFormValues.ticketStatusId = this.ticketDetails.ticketStatusId;
      this.updateFormValues.ticketPriorityId = this.ticketDetails.ticketPriorityId;
      this.updateDialog = true;
    },
    async updateRequest() {
      await updateTicket(this.clientId, this.ticketId, this.updateFormValues.assignedUserInfo.id, this.updateFormValues.ticketStatusId, this.updateFormValues.ticketPriorityId);
      await this.reload();
      this.updateDialog = false;
    },
    async addComment(comment) {
      await addComment(this.ticketId, comment);
    },
    async getComments() {
      return await getComments(this.ticketId);
    },
  },
};
</script>

<style scoped>
.glow {
  box-shadow: inset 0 0 24px #cccccc
}

.group-chat-wrapper {
  height:   calc(100vh - 225px);
  overflow: auto;
}
</style>
