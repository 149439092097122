import Model from './model';
import { Address } from '@/application/models/Address.js';

export class TicketDetail extends Model {
  id = 0;
  address = new Address();
  subject = '';
  description = '';
  relatedOrder = {};
  contactInfo = {};
  ticketStatusId = 0;
  assignedUserInfo = {};
  creationDate = '';
  images = [];
  startDate = '';
  assignedDate = '';
  ticketPriorityId = 0;
  lastUpdated = '';
  elapsedTime = '';
  resolvedDate = '';
  inProgressDate = '';
  images = [];
  isLoaded = false;

  mapResponse(data) {
    return super.mapResponse(data);
  }
}
