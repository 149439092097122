<template>
  <div class="d-flex flex-wrap">
    <TicketImage
      v-for="(img, index) in ticketImages"
      :key="index"
      :image="img"
    />
  </div>
</template>

<script>

import TicketImage from '@/modules/tickets/components/TicketImage.vue';

export default {
  name: 'TicketImageGroup',
  components: { TicketImage },
  props: {
    ticketImages: {
      type: Array,
      required: true,
    },
  }
};
</script>
