<template>
  <k-field-group language-prefix="ticket.tickets.edit">
    <k-form-dialog v-bind="$attrs" v-on="$listeners" :panels="panels" :isUpdateForm="true" :title="$t('ticket.tickets.edit.ticket')">
      <template #panel.0>
        <v-row class="fill-height">
          <v-col cols="6">
            <form-sheet class="fill-height">
              <AssignAutocomplete
                v-if="values.ticketStatusId !== ticketStatus.RESOLVED"
                :return-object="false"
                v-model="values.assignedUserInfo.id"
                field="assignTo"
                item-text="fullName"
                item-value="id"
                hide-details
                :required="values.ticketStatusId === ticketStatus.ASSIGNED || values.ticketStatusId === ticketStatus.IN_PROGRESS"
                class="mb-2 pb-1"/>
              <KRadioGroup
                required
                :items="selectableTicketStatus"
                v-model="values.ticketStatusId"
                :persistent-hint="false"
                dense
                field="status"
                hide-details
                class="mb-2 pb-1"/>
              <KRadioGroup
                required
                :items="selectableTicketPriorities"
                v-model="values.ticketPriorityId"
                :persistent-hint="false"
                dense
                field="priority"
                hide-details
                class="mb-2 pb-1"/>
            </form-sheet>
          </v-col>
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import FormSheet from '@/components/FormSheet.vue';
import AssignAutocomplete from '@/modules/tickets/components/AssignAutocomplete.vue';
import { getTranslatedEnumOptions } from '@/application/helpers/enum.js';
import ticketStatus from '@/application/enums/ticketStatus.js';
import ticketPriorities from '@/application/enums/ticketPriorities.js';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';

export default {
  name: 'UpdateTicketDetails',
  components: {
    KRadioGroup,
    AssignAutocomplete,
    KFormDialog,
    KFieldGroup,
    FormSheet,
  },
  props: {
    values: {
      type: Object,
    }
  },
  watch: {
    'values.ticketStatusId': {
      handler(newVal) {
        if(newVal === ticketStatus.RESOLVED){
          this.values.assignedUserInfo.id = null;
        }
      },
    },
  },
  computed: {
    ticketStatus() {
      return ticketStatus;
    },
    panels() {
      return [
        { name: this.$t('global.defaultTabName') }
      ];
    },
    selectableTicketStatus() {
      return getTranslatedEnumOptions(ticketStatus, 'ticket.ticketStatus');
    },
    selectableTicketPriorities() {
      return getTranslatedEnumOptions(ticketPriorities, 'ticket.ticketPriorities');
    },
  },
};
</script>
